import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

const ArchiveWrapper = styled.div`
  margin-left: 6.4rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  &::before {
    content: '';
    z-index: -1;
    background-color: ${({ theme }) => theme.primary};
    clip-path: polygon(50% 0px, 0% 100%, 100% 100%);
    width: 100%;
    height: 7.2rem;
    position: absolute;
    top: -3.6rem;
    left: 0;
  }
  &::after {
    content: '';
    z-index: -1;
    background-color: ${({ theme }) => theme.primary};
    clip-path: polygon(0% 0px, 50% 100%, 100% 0%);
    width: 100%;
    height: 7.2rem;
    position: absolute;
    bottom: -8rem;
    left: 0;
  }
`

const ArchiveTitle = styled.h3`
  margin: 0 0 1.6rem 0;
  font-weight: 600;
  font-size: 2rem;
`

const ArchiveList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

const ArchiveItem = styled.li`
  margin: 0;
`

const ArchiveLink = styled(Link)`
  display: block;
  padding: 0.8rem 1.6rem;
  color: ${({ theme }) => theme.black};
  background-color: ${({ theme }) => theme.grey};
  text-decoration: none;
  font-weight: 600;
  font-size: 1.6rem;
  transition: background-color 0.25s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.primary};
  }
`

const Archive = () => {
  const data = useStaticQuery(graphql`
    query {
      allVolume: allDatoCmsVolume(sort: { fields: no, order: DESC }) {
        edges {
          node {
            slug
            name
            year
            no
          }
        }
      }
    }
  `)
  const primary = 'hsl(49, 100%, 58%)'
  const volumes = data.allVolume.edges
  return (
    <ArchiveWrapper>
      <ArchiveTitle>Issues</ArchiveTitle>
      <ArchiveList>
        {volumes.map(({ node }) => {
          const { slug, no, year } = node
          return volumes.length === no ? (
            <ArchiveItem key={slug}>
              <ArchiveLink
                to={`/issues/`}
                activeStyle={{ backgroundColor: primary }}
              >
                Volume {no} ({year})
              </ArchiveLink>
            </ArchiveItem>
          ) : (
            <ArchiveItem key={slug}>
              <ArchiveLink
                to={`/issues/${slug}/`}
                activeStyle={{ backgroundColor: primary }}
              >
                Volume {no} ({year})
              </ArchiveLink>
            </ArchiveItem>
          )
        })}
      </ArchiveList>
    </ArchiveWrapper>
  )
}

export default Archive

import React, { useState } from 'react'
import { graphql } from 'gatsby'
import SEO from 'components/SEO/SEO'
import styled from 'styled-components'
import {
  ContentSection,
  ContentTitle,
} from 'components/StyledComponents/StyledComponents'
import Archive from 'components/Archive/Archive'
import Modal from 'components/Modal/Modal'
import FileSaver from 'file-saver'

const FlexSection = styled.div`
  margin-top: 4.8rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`

const ArticlesSection = styled.div`
  max-width: 896px;
`

const ArticlesHeader = styled.div`
  margin-bottom: 7.2rem;
  padding-left: 0.8rem;
  border-left: 1.2rem solid ${({ theme }) => theme.primary};
`

const ArticlesVolume = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 2rem;
`

// const ArticlesTitle = styled.p`
//   margin: 0.8rem 0 0;
//   font-weight: 600;
//   font-size: 1.8rem;
// `

const ArticlesEditorWrapper = styled.div`
  margin-top: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const ArticlesEditorTitle = styled.p`
  margin: 0 0.8rem 0 0;
  font-size: 1.6rem;
  font-weight: 600;
`

const ArticlesEditor = styled.p`
  margin: 0 0.8rem 0 0;
  font-weight: 600;
  font-size: 1.6rem;
  font-style: italic;
  &::after {
    content: ',';
  }
  &:last-child::after {
    content: '';
  }
`

const ArticleSection = styled.section`
  margin: 0 0 6.4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
`

const ArticleTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 1.8rem;
  position: relative;
  z-index: 1;
  &::before {
    content: '';
    z-index: -1;
    background-color: ${({ theme }) => theme.primary};
    clip-path: polygon(0% 0px, 0% 100%, 100% 100%);
    width: 4.8rem;
    height: 3.6rem;
    position: absolute;
    top: -0.8rem;
    left: -0.4rem;
  }
`

const ArticleAuthorWrapper = styled.div`
  margin-top: 0.8rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const ArticleAuthor = styled.p`
  margin: 0 0.8rem 0 0;
  font-weight: 600;
  font-size: 1.6rem;
  font-style: italic;
  &::after {
    content: ',';
  }
  &:last-child::after {
    content: '';
  }
`

const ArticlePages = styled.p`
  margin: 1.6rem 0 0;
  font-weight: 400;
  font-size: 1.4rem;
`

const ArticleButtons = styled.div`
  margin: 0.8rem 4.8rem 0 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ArticleButton = styled.button`
  margin-left: 3.2rem;
  padding: 0.8rem 1.6rem;
  flex-shrink: 0;
  position: relative;
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.black};
  border: none;
  z-index: 1;
  &::before {
    content: '';
    background-color: ${({ theme }) => theme.primary};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: -1;
    transition: transform 0.15s ease-out 0s;
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid ${({ theme }) => theme.black};
    transition: transform 0.15s ease-out 0s;
  }
  &:hover::before {
    transform: translate(5px, -5px);
  }
  &:hover::after {
    transform: translate(-5px, 5px);
  }
`

const AbstractWrapper = styled.div`
  margin-top: 1.6rem;
  border: 1px solid ${({ theme }) => theme.black};
  padding: 2.4rem;
  background-color: #fff;
`

const AbstractTitle = styled.p`
  margin: 0;
  font-size: 1.6rem;
  font-weight: 600;
`

const Abstract = styled.p`
  margin: 0;
  font-size: 1.6rem;
  text-align: justify;
`

const IssuePage = ({ data }) => {
  // const [isModalOpen, setModalOpen] = useState(false)
  // const [modalTitle, setModalTitle] = useState('')
  // const [modalAbstract, setModalAbstract] = useState('')
  // const toggleAbstract = (title, abstract) => {
  //   setModalOpen(!isModalOpen)
  //   setModalTitle(title)
  //   setModalAbstract(abstract)
  // }
  const issues = data.allArticle.edges
  const {
    name: volumeName,
    year: volumeYear,
    no: volumeNumber,
    // title: volumeTitle,
    editor: volumeEditor,
  } = issues[0].node.volume
  const [isAbstractOpen, setAbstractOpen] = useState(
    new Array(issues.length).fill(false)
  )
  const handleAbstract = index => {
    const Abs = [...isAbstractOpen]
    Abs[index] = !isAbstractOpen[index]
    setAbstractOpen([...Abs])
  }
  return (
    <>
      <SEO title={`Issues | ${volumeName} (${volumeYear})`} />
      <ContentSection>
        <ContentTitle>
          Issues >>{' '}
          {18 === volumeNumber
            ? 'Current issue'
            : `${volumeName} (${volumeYear})`}
        </ContentTitle>
        <FlexSection>
          <ArticlesSection>
            <ArticlesHeader>
              <ArticlesVolume>
                Rural Areas and Development, {volumeName} ({volumeYear})
              </ArticlesVolume>
              {/* <ArticlesTitle>{volumeTitle}</ArticlesTitle> */}
              <ArticlesEditorWrapper>
                <ArticlesEditorTitle>Editor:</ArticlesEditorTitle>
                {volumeEditor.map(({ name: editor }) => (
                  <ArticlesEditor key={editor}>{editor}</ArticlesEditor>
                ))}
              </ArticlesEditorWrapper>
            </ArticlesHeader>
            {issues.map(({ node }, index) => {
              const {
                title,
                slug,
                author,
                volume,
                pageFrom,
                pageTo,
                pdf,
                abstract,
              } = node
              return (
                <ArticleSection key={slug}>
                  <ArticleTitle>{title}</ArticleTitle>
                  <ArticleAuthorWrapper>
                    {author.map(({ name }) => (
                      <ArticleAuthor key={name}>{name}</ArticleAuthor>
                    ))}
                  </ArticleAuthorWrapper>
                  <ArticlePages>
                    Rural Areas and Development, {volume.name} ({volume.year}),
                    Pages {pageFrom}-{pageTo}
                  </ArticlePages>
                  <ArticleButtons>
                    <ArticleButton
                      // onClick={() => toggleAbstract(title, abstract)}
                      onClick={() => handleAbstract(index)}
                    >
                      Abstract
                    </ArticleButton>
                    <ArticleButton
                      onClick={() =>
                        FileSaver.saveAs(`${pdf.url}`, `${pdf.title}.pdf`)
                      }
                    >
                      PDF
                    </ArticleButton>
                  </ArticleButtons>
                  {isAbstractOpen[index] && (
                    <AbstractWrapper>
                      <AbstractTitle>Abstract</AbstractTitle>
                      <Abstract>{abstract}</Abstract>
                    </AbstractWrapper>
                  )}
                </ArticleSection>
              )
            })}
            {/* {isModalOpen ? (
              <Modal
                toggleAbstract={toggleAbstract}
                modalTitle={modalTitle}
                modalAbstract={modalAbstract}
              />
            ) : null} */}
          </ArticlesSection>
          <Archive />
        </FlexSection>
      </ContentSection>
      {/* {console.log(isAbstractOpen)} */}
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    allArticle: allDatoCmsArticle(
      filter: { volume: { slug: { eq: $slug } } }
      sort: { fields: pageFrom }
    ) {
      edges {
        node {
          title
          slug
          author {
            name
          }
          volume {
            name
            year
            no
            title
            editor {
              name
            }
            slug
          }
          pageFrom
          pageTo
          pdf {
            url
            title
          }
          abstract
        }
      }
    }
  }
`

export default IssuePage

import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

const ModalOutter = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2020;
  background-color: rgba(10, 10, 10, 0.3);
`

const ModalWrapper = styled.div`
  margin: auto 3.2rem;
  max-height: 100vh;
  max-width: 1024px;
  border: 5px solid ${({ theme }) => theme.black};
  padding: 4rem;
  background-color: ${({ theme }) => theme.white};
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 20px -15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
`

const ModalCloseButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border-style: none;
  padding: 20px;
  &::before,
  &::after {
    content: '';
    width: 20px;
    height: 3px;
    background-color: black;
    position: absolute;
  }
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
`

const ArticleTitle = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 1.8rem;
`

const AbstractTitle = styled.p`
  margin: 1.2rem 0 0 0;
  font-weight: 600;
  font-size: 1.6rem;
`

const Abstract = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: justify;
`

const Modal = ({ toggleAbstract, modalTitle, modalAbstract }) => {
  const modalRef = useRef(null)
  const listener = event => {
    if (!modalRef.current || modalRef.current.contains(event.target)) {
      return
    }
    toggleAbstract('')
  }

  useEffect(() => {
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  })
  return (
    <ModalOutter>
      <ModalWrapper ref={modalRef}>
        <ModalCloseButton onClick={() => toggleAbstract('')} />
        <ArticleTitle>{modalTitle}</ArticleTitle>
        <AbstractTitle>Abstract</AbstractTitle>
        <Abstract>{modalAbstract}</Abstract>
      </ModalWrapper>
    </ModalOutter>
  )
}

export default Modal
